.swiper-button-prev,
.swiper-button-next {
  display: none;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 24px;
}

.swiper:hover .swiper-button-prev,
.swiper:hover .swiper-button-next {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: hsl(var(--primary));
  color: hsl(var(--primary-foreground));
  border-radius: 50%;
  transition: transform 0.4s ease;
}

.swiper:hover .swiper-button-prev {
  transform: translateX(30px);
}

.swiper:hover .swiper-button-next {
  transform: translateX(-30px);
}

.rgb-all-242 {
  background-color: rgb(242 242 242 / var(--tw-bg-opacity));
}

.rgb-all-230 {
  background-color: rgb(230 230 230 / var(--tw-bg-opacity));
}

.loader {
  position: relative;
  transform: rotateZ(45deg);
  width: 48px;
  height: 48px;
  color: hsl(var(--muted-foreground));
}

.loader:before,
.loader:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transform: translate(-50%, -50%) rotateX(70deg);
  animation: 1s spin linear infinite;
}

.loader:after {
  color: hsl(var(--primary));
  transform: translate(-50%, -50%) rotateY(70deg);
  animation-delay: 0.4s;
}

@keyframes spin {

  0%,
  100% {
    box-shadow: 0.2em 0px 0 0px currentcolor;
  }

  12% {
    box-shadow: 0.2em 0.2em 0 0 currentcolor;
  }

  25% {
    box-shadow: 0 0.2em 0 0px currentcolor;
  }

  37% {
    box-shadow: -0.2em 0.2em 0 0 currentcolor;
  }

  50% {
    box-shadow: -0.2em 0 0 0 currentcolor;
  }

  62% {
    box-shadow: -0.2em -0.2em 0 0 currentcolor;
  }

  75% {
    box-shadow: 0px -0.2em 0 0 currentcolor;
  }

  87% {
    box-shadow: 0.2em -0.2em 0 0 currentcolor;
  }
}